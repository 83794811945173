import React from 'react';
import ZoomableField from './ZoomableField';
import './ZoomableFieldPage.css';

const ZoomableFieldPage = () => {
  return (
    <div className="zoomable-field-page">
      <ZoomableField />
    </div>
  );
};

export default ZoomableFieldPage;
