import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import MainContent from './MainContent';
import Footer from './Footer';
import ZoomableFieldPage from './ZoomableFieldPage'; // Import the new page component
import './App.css';
import './fonts.css';
import './Grid.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <div className='grid'></div>
        <div className='bg-overlay'></div>
        <Navbar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/field" element={<ZoomableFieldPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
