// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"; // Import for Realtime Database

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvgX13ZfQeW8KbQaX3QAM-eWacG_1un0o",
  authDomain: "test2-e7011.firebaseapp.com",
  databaseURL: "https://test2-e7011-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test2-e7011",
  storageBucket: "test2-e7011.appspot.com",
  messagingSenderId: "950294124739",
  appId: "1:950294124739:web:4ba3721c87b3364359aa76",
  measurementId: "G-1S8C59955D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app); // Initialize Realtime Database

export { app, analytics, database };
