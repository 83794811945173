import React, { useState, useEffect } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import './ZoomableField.css';
import './MapCheck.css'; // Ensure this CSS file is included
import { database } from './firebase';
import { ref, onValue, off } from 'firebase/database';

const totalItems = 250000; // Include item 0 to 250,000
const columnCount = 665; // Adjust based on the expected layout
const rowCount = Math.ceil(totalItems / columnCount);

const Cell = ({ columnIndex, rowIndex, style, onClick, existsInDatabase }) => {
  const itemIndex = rowIndex * columnCount + columnIndex;
  if (itemIndex > totalItems) return null;

  return (
    <button
      style={style}
      className={`field-item ${existsInDatabase ? 'exists-in-db' : ''}`}
      onClick={() => onClick(itemIndex)}
    >
      {itemIndex}
    </button>
  );
};

const ZoomableField = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [traits, setTraits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mintLink, setMintLink] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const [mintPrice, setMintPrice] = useState(null);
  const [tokenAddress, setTokenAddress] = useState('Loading...');
  const [existingItems, setExistingItems] = useState(new Set());

  useEffect(() => {
    const mintPriceRef = ref(database, '/mintPrice');
    const unsubscribe = onValue(mintPriceRef, (snapshot) => {
      const price = snapshot.val();
      setMintPrice(price);
    });

    const addressRef = ref(database, '/address');
    const addressListener = onValue(addressRef, (snapshot) => {
      const addresses = snapshot.val();
      if (addresses) {
        const existingSet = new Set(Object.keys(addresses).map(Number));
        setExistingItems(existingSet);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      off(mintPriceRef);
      off(addressRef, 'value', addressListener);
    };
  }, []);

  const fetchTokenAddress = async (id) => {
    const addressRef = ref(database, `/address/${id}`);
    onValue(addressRef, (snapshot) => {
      const address = snapshot.val();
      if (address) {
        setTokenAddress(address);
      } else {
        setTokenAddress('NOT YET AVAILABLE');
      }
    }, (error) => {
      console.error('Error fetching token address:', error);
      setTokenAddress('NOT YET AVAILABLE');
    });
  };

  const fetchItemData = async (id) => {
    setLoading(true);
    setError('');
    setImageUrl('');
    setTraits(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_LINK_METADATA}/${id}.json`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      if (data) {
        setImageUrl(data.image || '');
        if (data.traits) {
          const formattedTraits = data.traits.reduce((acc, trait) => {
            const key = Object.keys(trait)[0];
            acc[key] = trait[key];
            return acc;
          }, {});
          setTraits(formattedTraits);
          setMintLink(data.link || '');
          fetchTokenAddress(id);
        } else {
          setTraits({});
        }
      } else {
        setError(`TONMAP #${id} DOES NOT EXIST`);
      }
    } catch (error) {
      console.error('Error fetching the IPFS JSON:', error);
      setError(`TONMAP #${id} DOES NOT EXIST`);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (itemIndex) => {
    setSelectedItem(itemIndex);
    fetchItemData(itemIndex);
  };

  const handleClose = () => {
    setSelectedItem(null);
  };

  return (
    <div className="zoomable-field">
      <Grid
        className="zoomable-content"
        columnCount={columnCount}
        columnWidth={65} // Width adjusted for spacing
        height={window.innerHeight * 0.9}
        rowCount={rowCount}
        rowHeight={65} // Height adjusted for spacing
        width={window.innerWidth * 0.9}
      >
        {({ columnIndex, rowIndex, style }) => (
          <Cell
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            style={style}
            onClick={handleClick}
            existsInDatabase={existingItems.has(rowIndex * columnCount + columnIndex)}
          />
        )}
      </Grid>

      {selectedItem !== null && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClose}>&times;</span>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <div>
                <div className='mainContainer_top'>
                  <p className='xs'>TOKEN ADDRESS:
                    <a style={{ color: 'white', textDecoration: 'none' }} href={`https://tonviewer.com/${tokenAddress}`} target="_blank" rel="noreferrer"> {tokenAddress}</a>
                  </p>
                </div>
                <div className="mainContainer">
                  <div className="imageContainer">
                    {imageLoading && <p style={{ fontSize: '20px', color: '#333' }}>Loading image...</p>}
                    <img
                      src={imageUrl || './loading.png'}
                      alt={`NFT ${selectedItem}`}
                      className="image"
                      onLoad={() => setImageLoading(false)}
                      onError={(e) => {
                        e.target.src = './loading.png';
                        setImageLoading(false);
                      }}
                    />
                    {tokenAddress === 'NOT YET AVAILABLE' ? (
                      <a href={`ton://transfer/${process.env.REACT_APP_CONTRACT}?text=Deploy%20contract&amount=${mintPrice}&${mintLink}`}><button className="main-button">MINT</button></a>
                    ) : (
                      <a href={`https://getgems.io/nft/${tokenAddress}`} target="_blank" rel="noreferrer"><button className="main-button" style={{ fontSize: '22px' }}>BUY ON GETGEMS</button></a>
                    )}
                  </div>
                  <div className="traitsContainer">
                    <div className="trait">
                      <p className='xs'>Map number:</p>
                      <p className='xl' style={{ marginTop: '8px' }}>{selectedItem}</p>
                    </div>
                    {traits && Object.keys(traits).map((key) => (
                      <div className="trait" key={key}>
                        <p className='xs'>{key}:</p>
                        <p className='xl' style={{ marginTop: '8px' }}>{traits[key]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomableField;
