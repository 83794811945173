import React, { useState, useEffect } from 'react';
import './MapCheck.css';
import { database } from './firebase';
import { ref, onValue, off } from 'firebase/database';

const MapCheck = () => {
    const [nftId, setNftId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [traits, setTraits] = useState(null);
    const [error, setError] = useState('');
    const [mintLink, setMintLink] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [mintPrice, setMintPrice] = useState(null);
    const [tokenAddress, setTokenAddress] = useState('Loading...');

    useEffect(() => {
        const mintPriceRef = ref(database, '/mintPrice');
        // eslint-disable-next-line
        const unsubscribe = onValue(mintPriceRef, (snapshot) => {
            const price = snapshot.val();
            setMintPrice(price);
        });

        // Clean up the subscription when the component unmounts
        return () => {
            off(mintPriceRef);
        };
    }, []);

    const fetchTokenAddress = async (id) => {
        const addressRef = ref(database, `/address/${id}`);
        onValue(addressRef, (snapshot) => {
            const address = snapshot.val();
            if (address) {
                setTokenAddress(address);
            } else {
                setTokenAddress('NOT YET AVAILABLE');
            }
        }, (error) => {
            console.error('Error fetching token address:', error);
            setTokenAddress('NOT YET AVAILABLE');
        });
    };

    const handleSearch = async () => {
        const id = parseInt(inputValue, 10);
        if (isNaN(id) || id < 0 || id > 250000) {
            setError('PLEASE ENTER A VALID TONMAP NUMBER BETWEEN 0 AND 250000');
            setTraits(null);
            setImageUrl('');
            setMintLink('');
            setLoading(false);
            return;
        }

        setNftId(inputValue); // Update nftId state
        setLoading(true);
        setImageLoading(true);
        setError('');
        setTraits(null);
        setImageUrl('');
        setMintLink('');

        try {
            const response = await fetch(`${process.env.REACT_APP_LINK_METADATA}/${id}.json`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            
            if (data && data.traits) {
                const formattedTraits = data.traits.reduce((acc, trait) => {
                    const key = Object.keys(trait)[0];
                    acc[key] = trait[key];
                    return acc;
                }, {});

                setTraits(formattedTraits);
                setImageUrl(data.image);
                setMintLink(data.link || '');
                setError('');
                fetchTokenAddress(id);
            } else {
                setError(`TONMAP #${nftId} DOES NOT EXIST`);
            }
        } catch (error) {
            console.error('Error fetching the IPFS JSON:', error);
            setError(`TONMAP #${nftId} DOES NOT EXIST`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            {!traits && (
                <div className="inputContainer">
                    <h1 style={{marginLeft: '2px', fontSize: '50px', marginBottom: '8px' }} className='bluelight'>SCAN FOR TONMAP</h1>
                    <label>
                        <input
                            placeholder='INSERT TONMAP NUMBER BETWEEN 0 AND 250000'
                            className="input-scan"
                            type="number"
                            min="0"
                            max="250000"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                        <button className='scan-button' onClick={handleSearch}>SCAN</button>
                    </label>
                    {error && <p style={{ marginLeft: '11px', fontSize: '20px', color: '#333' }} className="error">{error}</p>}
                </div>
            )}

            {loading && <p style={{ fontSize: '20px', color: '#333' }}>Loading...</p>}

            {traits && !loading && (
                <div>
                    <div className="inputContainer2">
                        <label className='label2'>
                            <p style={{ textAlign: 'left !important', color: 'white' }} className='bluelight'>SCAN FOR TONMAP</p>
                            <input
                                className="input-scan2"
                                type="number"
                                min="0"
                                max="250000"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <button className='scan-button2' onClick={handleSearch}>SCAN</button>
                        </label>
                        {error && <p style={{ marginLeft: '11px', fontSize: '20px', color: '#333' }} className="error">{error}</p>}
                    </div>
                    <div className='mainContainer_top'>
                        <p className='xs'>
                            TOKEN ADDRESS: <a style={{color: 'white', textDecoration: 'none'}} href={`https://tonviewer.com/${tokenAddress}`} target="_blank" rel="noreferrer">{tokenAddress}</a>
                        </p>
                    </div>
                    <div className="mainContainer">
                        <div className="imageContainer">
                            {imageLoading && <p style={{ fontSize: '20px', color: '#333' }}>Loading image...</p>}
                            <img 
                                src={imageUrl || './loading.png'} 
                                alt={`NFT ${nftId}`} 
                                className="image" 
                                onLoad={() => setImageLoading(false)} 
                                onError={(e) => {
                                    e.target.src = './loading.png';
                                    setImageLoading(false);
                                }} 
                            />
                            {tokenAddress === 'NOT YET AVAILABLE' ? (
                                <a href={`ton://transfer/${process.env.REACT_APP_CONTRACT}?text=Deploy%20contract&amount=${mintPrice}&${mintLink}`}><button className="main-button">MINT</button></a>
                            ) : (
                                <a href={`https://getgems.io/nft/${tokenAddress}`} target="_blank" rel="noreferrer"><button className="main-button" style={{fontSize: '22px'}}>BUY ON GETGEMS</button></a>
                            )}
                        </div>
                        <div className="traitsContainer">
                            <div className="trait">
                                <p className='xs'>Map number:</p>
                                <p className='xl'>{nftId}</p>
                            </div>
                            {traits.Fibonacci !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Fibonacci:</p>
                                    <p className='xl'>{traits.Fibonacci}</p>
                                </div>
                            )}
                            {traits.Number_of_digits !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Number of Digits:</p>
                                    <p className='xl'>{traits.Number_of_digits}</p>
                                </div>
                            )}
                            {traits.Pure !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Pure:</p>
                                    <p className='xl'>{traits.Pure}</p>
                                </div>
                            )}
                            {traits.Same_digits !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Same Digits:</p>
                                    <p className='xl'>{traits.Same_digits}</p>
                                </div>
                            )}
                            {traits.Palindrome !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Palindrome:</p>
                                    <p className='xl'>{traits.Palindrome}</p>
                                </div>
                            )}
                            {traits.Sequential_numbers !== undefined && (
                                <div className="trait">
                                    <p className='xs'>Sequential Numbers:</p>
                                    <p className='xl'>{traits.Sequential_numbers}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MapCheck;
