import React from 'react';
import './MainContent.css';
// import TonLinkGenerator from './TonLinkGenerator'; 
import MapCheck from "./MapCheck";
import ZoomableField from './ZoomableField';


const MainContent = () => {
  return (
    <main className="main-content">
      <div className="button-container">
        <MapCheck />
        {/* <ZoomableField /> */}
        {/* <TonLinkGenerator /> */}
      </div>
    </main>
  );
};

export default MainContent;
