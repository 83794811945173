import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Navbar.css';
import { database } from './firebase';
import { ref, onValue, off } from 'firebase/database';

const Navbar = () => {
  const location = useLocation();
  const isFieldPage = location.pathname === '/field';
  const [mintPrice, setMintPrice] = useState(null);

  useEffect(() => {
    const mintPriceRef = ref(database, '/mintPrice');
    const unsubscribe = onValue(mintPriceRef, (snapshot) => {
      const price = snapshot.val();
      setMintPrice(price);
    });

    // Clean up the subscription when the component unmounts
    return () => {
      off(mintPriceRef);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a
          style={{ color: 'red !important' }}
          className='bluelight-nav'
          href="/">
          <p>OPEN</p>
          <p>TONMAP {isFieldPage ? 'FIELD' : 'SCAN'}</p>
          <p style={{ textAlign: 'right', fontSize: '12px' }}>ALPHA</p>
        </a>
      </div>
      <ul className="navbar-links">
        <li><a href="/" rel="noopener noreferrer">SCANNER</a></li>
        <li><a href="/field" rel="noopener noreferrer">FIELD</a></li>
        <li>
          <a 
            href={`ton://transfer/${process.env.REACT_APP_CONTRACT}?amount=${mintPrice}&text=Mint`}
            // target='_blank' 
            rel="noopener noreferrer"
          >
            RANDOM MINT
          </a>
        </li>
        <li>
          <a 
            href={`https://getgems.io/opentonmap`}
            target='_blank' 
            rel="noopener noreferrer"
          >
            BUY OPEN TONMAP
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
